import React from "react";

interface LangIconProps {
  fillColor: string;
}

const LangIcon: React.FC<LangIconProps> = ({ fillColor }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill={fillColor} version="1.1" x="0px" y="0px" viewBox="0 0 24 30" xmlSpace="preserve" height="30px">
      <g>
        <g>
          <path d="M12,2C6.4771729,2,2,6.4771118,2,12c0,5.5228271,4.4771729,10,10,10s10-4.4771729,10-10C22,6.4771118,17.5228271,2,12,2z M12,21c-0.7915649,0-1.8123169-1.4749756-2.4360352-4h4.8720703C13.8123169,19.5250244,12.7915649,21,12,21z M9.3495483,16    c-0.1907959-1.0349731-0.3134766-2.208313-0.3394775-3.5h5.9798584c-0.026001,1.291687-0.1486816,2.4650269-0.3394775,3.5    H9.3495483z M3.0253296,12.5h4.9847412C8.034668,13.7391357,8.1461792,14.9197998,8.335083,16H3.949646    C3.4181519,14.9348145,3.0946045,13.7520752,3.0253296,12.5z M12,3c0.7915649,0,1.8123169,1.4749756,2.4360352,4H9.5639648    C10.1876831,4.4749756,11.2084351,3,12,3z M14.6504517,8c0.1907959,1.0349731,0.3134766,2.208313,0.3394775,3.5H9.0100708    c0.026001-1.291687,0.1486816-2.4650269,0.3394775-3.5H14.6504517z M8.0100708,11.5H3.0253296    C3.0946045,10.2479248,3.4181519,9.0651855,3.949646,8h4.385437C8.1461792,9.0802002,8.034668,10.2608643,8.0100708,11.5z     M15.9899292,12.5h4.9847412c-0.0692749,1.2520752-0.3928223,2.4348145-0.9243164,3.5h-4.385437    C15.8538208,14.9197998,15.965332,13.7391357,15.9899292,12.5z M15.9899292,11.5    C15.965332,10.2608643,15.8538208,9.0802002,15.664917,8h4.385437c0.5314941,1.0651855,0.8550415,2.2479248,0.9243164,3.5    H15.9899292z M19.4782104,7h-4.0177002c-0.3689575-1.5924683-0.9054565-2.9121094-1.5532227-3.7921753    C16.2182617,3.7088623,18.2015991,5.0969238,19.4782104,7z M10.0927124,3.2078247    C9.4449463,4.0878906,8.9084473,5.4075317,8.5394897,7H4.5217896C5.7984009,5.0969238,7.7817383,3.7088623,10.0927124,3.2078247z     M4.5217896,17h4.0177002c0.3689575,1.5924072,0.9055176,2.9120483,1.5532837,3.7921753    C7.7817993,20.2911377,5.7984009,18.9031372,4.5217896,17z M13.9072266,20.7921753    C14.5549927,19.9120483,15.0915527,18.5924072,15.4605103,17h4.0177002    C18.2015991,18.9031372,16.2182007,20.2911377,13.9072266,20.7921753z" />
        </g>
      </g>
    </svg>
  );
};

export default LangIcon;
